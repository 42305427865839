<template>
  <div>
    <Pipeline
      :name="pipelineName"
      :title="pipelineTitle"
      :resTable="resTable"
      :filteredResTable="filteredResTable"
      :headers="headers"
      :pipelineId="pipelineId"
    >
    </Pipeline>
  </div>
</template>

<script>
import Pipeline from "../components/Pipeline";
import resTable from "../assets/pipeline2_res_table_211123.json";
import filteredResTable from "../assets/pipeline2_filtered_res_table_211123.json";
import headers from "../assets/pipeline2_colnames_211123.json";
import _ from "lodash"

export default {
  name: "Pipeline2",

  components: {
    Pipeline,
  },

  data() {
    return {
      pipelineId: 1,
      pipelineName: "Pipeline 2",
      pipelineTitle:
        "eQTL indentification based on SVA and GC corrected and quantile normalized Gene expression values",
      resTable: [],
      filteredResTable: [],
      headers: [],
    };
  },

  created() {
    function prepareFields(field, i) {
      field.id = i

      field.Plots = {
        pipelineId: 1,
        id: i,
        value: field.gene_associated.value,
        boxplot: {
          fileName: field.box_plot.link.replace(".html", ".json"),
          title: field.box_plot.value,
        },
        manhattan: {
          fileName: field.manhattan_plot.link.replace(".html", ".json"),
          title: field.manhattan_plot.value,
        },
        volcano: {
          fileName: field.volcano_plot.link.replace(".html", ".json"),
          title: field.volcano_plot.value,
        },
        boxplotFitted: {
          fileName: field.box_plot_fitted.link.replace(".html", ".json"),
          title: field.box_plot_fitted.value
        }
      };
      return field;
    }

    this.resTable = _.map(resTable, prepareFields);
    this.filteredResTable = _.map(filteredResTable, prepareFields);
    this.headers = headers.filter((h) => {
      return ! ["box_plot", "manhattan_plot", "volcano_plot", "box_plot_fitted"].includes(h.value)
    })

  },
};
</script>
